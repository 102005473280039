<template>
  <Head>
    <Meta name="viewport" :content="viewport"></Meta>
    <!-- viewport value is set in plugins/adjustTabletTouchScreenScaling.ts -->
    <Meta charset="utf-8"></Meta>
    <Meta name="robots" content="noarchive"></Meta>
  </Head>
</template>
<script setup lang="ts">
import { useAreaStore } from '~~/src/stores/useArea';
import { SiteIdent } from '~/@types/siteIdent';
import { useSessionStore } from '~/stores/useSessionStore';
import { useUserContext } from '~/stores/useUserContext';
import Hotjar from '@hotjar/browser';

const isServer = import.meta.server;
const viewport = ref('width=device-width, initial-scale=1');
const isTouch = ref(false);
const config = useRuntimeConfig();
const site = useSiteIdent();
const sessionStore = useSessionStore();
const userContext = useUserContext();

const customerId = ref<string | null>(null);

setMailchimpScript(); // Has to be set on SSR

if (!import.meta.server) {
  isTouch.value = window.matchMedia('(hover: none)').matches;
  setViewport(screen.width);
  setGTMScript();
  setHotjarScript();

  if (useShopEnabled()) {
    /**
     * EPOQ recommendations
     */
    const epoqTenantMap: Record<SiteIdent, string> = {
      [SiteIdent.default]: config.public.EPOQ_TENANT_ID_VDV as string,
      [SiteIdent.miniluDe]: config.public.EPOQ_TENANT_ID_MINILU_DE as string,
      [SiteIdent.miniluAt]: config.public.EPOQ_TENANT_ID_MINILU_AT as string,
      [SiteIdent.miniluNl]: config.public.EPOQ_TENANT_ID_MINILU_NL as string,
    };

    if (epoqTenantMap[site]) {
      const customerIdScript = document.createElement('script');
      customerIdScript.setAttribute('type', 'text/javascript');
      document.head.prepend(customerIdScript);

      watch(
        () => customerId.value,
        (customerId) => {
          if (customerId) {
            customerIdScript.appendChild(
              document.createTextNode(`epoq_customerId = '${customerId}';`),
            );
          } else {
            customerIdScript.innerHTML = '';
          }
        },
      );

      const epoqFlowScript = document.createElement('script');
      epoqFlowScript.setAttribute(
        'src',
        `https://cdn.epoq.de/flow/${epoqTenantMap[site]}.js`,
      );
      epoqFlowScript.setAttribute('class', 'cmplazyload');
      epoqFlowScript.setAttribute('data-cmp-vendor', 's1472');
      epoqFlowScript.setAttribute('type', 'text/plain');
      document.head.appendChild(epoqFlowScript);
      watch(
        [
          () => sessionStore.isLoggedIn,
          () => userContext.accountData?.customerNumber,
        ],
        ([isLoggedIn, customerNumber]) => {
          if (
            isLoggedIn &&
            customerNumber &&
            (
              window as Window &
                typeof globalThis & { cmpCustomVendorsConsent: string }
            )?.cmpCustomVendorsConsent?.includes('s1472')
          ) {
            customerIdScript.appendChild(
              document.createTextNode(`epoq_customerId = '${customerNumber}';`),
            );
            document.head.prepend(customerIdScript);
          } else {
            customerId.value = null;
            customerIdScript.innerHTML = '';
            customerIdScript.remove();
          }
        },
        { immediate: true },
      );
    }
  }

  window.addEventListener('resize', () => {
    setViewport(screen.width);
  });
}
if (!isServer) {
  useHead({
    script: [],
  });
}

const areaStore = useAreaStore();
const siteId = useSiteIdent();
const shopIsEnabled = useShopEnabled();

watch(
  () => areaStore.activeAreaId,
  () => {
    useHead({
      style: [
        {
          id: 'areaStyles',
          innerHTML: `:root {${areaStore.areaStyles};${areaStore.activeAreaStyles}}`,
        },
        // @INFO Sticky-Header Top-Spacing for elements on VDV without Shop => 0 because header isn't sticky
        {
          id: 'stickyHeader',
          innerHTML: `:root {--sticky-header-height: ${
            siteId === SiteIdent.default && !shopIsEnabled
              ? '0px'
              : 'var(--thm-spacing-stickyheader)'
          };}`,
        },
      ],
    });
  },
  { immediate: true },
);

function setViewport(screenWidth: number) {
  if (screenWidth < 1440 && screenWidth >= 768 && isTouch.value) {
    viewport.value = `width=1440, initial-scale=${screenWidth / 1440}`;
  } else {
    viewport.value = 'width=device-width, initial-scale=1';
  }
}

/**
 * GOOGLE Tag manager
 */
function setGTMScript() {
  const site = useSiteIdent();
  let gtmId: string = null;

  if (site === SiteIdent.default) {
    gtmId = config.public.GOOGLE_ANALYTICS_ID_VDV;
  } else {
    gtmId = config.public.GOOGLE_ANALYTICS_ID_MINILU;
  }

  addGtmScriptToHead(gtmId);
}

function addGtmScriptToHead(gtmId: string | null) {
  if (!gtmId) return;

  //@TODO: Should this be async/deferred?
  //@TODO: Recheck later (mid 2023) if we still need this "hacky" Solution or if useHead will work for this by then
  // A bit hacky but you can use "class" on a script in useHead sadly, but it's required that the scripts have that for the cookie manager :(

  const script = document.createElement('script');
  script.appendChild(
    document.createTextNode(
      `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmId}');;`,
    ),
  );
  script.setAttribute('class', 'cmplazyload');
  script.setAttribute('data-cmp-vendor', 's905');
  script.setAttribute('type', 'text/plain');
  document.head.appendChild(script);
}

function setMailchimpScript() {
  if (!useShopEnabled()) return;

  const mcIdMap: Record<SiteIdent, string> = {
    [SiteIdent.default]: config.public.MAILCHIMP_SCRIPT_ID_VDV,
    [SiteIdent.miniluDe]: config.public.MAILCHIMP_SCRIPT_ID_MINILU_DE,
    [SiteIdent.miniluAt]: config.public.MAILCHIMP_SCRIPT_ID_MINILU_AT,
    [SiteIdent.miniluNl]: config.public.MAILCHIMP_SCRIPT_ID_MINILU_NL,
  };
  const mcId = mcIdMap[site];
  if (!mcId) return;

  useHead({
    script: [
      {
        id: 'mailchimp_verification',
        src: `https://chimpstatic.com/mcjs-connected/js/users/a8780ef3529534dbe1b892a2a/${mcId}.js`,
        type: 'text/javascript',
      },
    ],
  });
}

function setHotjarScript() {
  if (!useShopEnabled()) return;

  const hjVersion = 6;
  const hjIdMap: Record<SiteIdent, string> = {
    [SiteIdent.default]: config.public.HOTJAR_ID_VDV,
    [SiteIdent.miniluDe]: config.public.HOTJAR_ID_MINILU_DE,
    [SiteIdent.miniluAt]: config.public.HOTJAR_ID_MINILU_AT,
    [SiteIdent.miniluNl]: config.public.HOTJAR_ID_MINILU_NL,
  };
  const hjId = hjIdMap[site];
  if (hjId) {
    Hotjar.init(parseInt(hjId), hjVersion);
  }
}
</script>
